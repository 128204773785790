'use client'

import React, { ComponentType, ReactNode, useRef, useState } from 'react'
import { UiComponent } from 'components/ui/types'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { ExternalLink } from 'react-feather'
import { nonValidUrl } from 'shared/urlHelpers'
import ReactTooltip from 'react-tooltip'

export type LinkProps = {
  children: ReactNode
  onClick?: () => void
  activeClassName?: string
  exactActive?: boolean
  pathExactActive?: boolean
  openInNewWindow?: boolean
  openInNewWindowIcon?: boolean
  activeEqualsTo?: string[]
  activeEqualsToFunc?: () => boolean
  tooltip?: string
  mobileTooltip?: boolean
} & NextLinkProps &
  UiComponent

// IMPORTANT :
// for mobileTooltip to work, one `<ReactTooltip />` must be placed on including component
// regular tooltip (only on desktop) works directly

const Link: ComponentType<LinkProps> = ({
  children,
  onClick,
  className = '',
  activeClassName = '',
  exactActive = false,
  pathExactActive = false,
  openInNewWindow = false,
  openInNewWindowIcon = false,
  activeEqualsTo = [],
  activeEqualsToFunc,
  tooltip,
  mobileTooltip = false,
  ...linkProps
}) => {
  // const { asPath } = useRouter()
  const pathname = usePathname()
  const router = useRouter()
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  // eslint-disable-next-line no-undef
  const tooltipRef = useRef<HTMLAnchorElement>(null)
  const href = linkProps.href.toString()
  const disabled = href === nonValidUrl

  let active: boolean = false
  if (pathname) {
    if (exactActive) {
      active = pathname === href
    } else if (pathExactActive) {
      active = pathname.split('?')[0] === href.split('?')[0]
    } else {
      active =
        pathname.startsWith(href) ||
        activeEqualsTo.filter((a) => pathname.startsWith(a)).length === 1 ||
        (activeEqualsToFunc && activeEqualsToFunc()) ||
        false
    }
  }

  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault()
    if (disabled) return

    if (mobileTooltip) {
      if (tooltipVisible) {
        if (tooltipRef.current) {
          ReactTooltip.hide(tooltipRef.current)
        }
        if (onClick) {
          onClick()
        }
        if (openInNewWindow) {
          window.open(href, '_blank', 'noopener')
        } else {
          router.push(href)
        }
      } else {
        setTooltipVisible(true)
        if (tooltipRef.current) {
          ReactTooltip.show(tooltipRef.current)
        }
      }
    } else {
      if (onClick) {
        onClick()
      }
      if (openInNewWindow) {
        window.open(href, '_blank', 'noopener')
      } else {
        router.push(href)
      }
    }
  }

  return (
    <>
      <NextLink legacyBehavior {...linkProps}>
        <a
          className={`link ${className} ${active ? activeClassName : ''} ${
            openInNewWindowIcon ? 'link-icon' : ''
          } ${disabled ? 'disabled' : ''}`}
          onClick={handleOnClick}
          target={`${openInNewWindow ? '_blank' : ''}`}
          ref={tooltipRef}
          data-tip={tooltip}
        >
          {children}
          {openInNewWindowIcon && <ExternalLink />}
        </a>
      </NextLink>
    </>
  )
}

export default Link
